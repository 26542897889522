

































































































































































































































































































import {Component, Vue, Watch} from "vue-property-decorator";
import Toolbar from "@/components/Toolbar.vue";
import UtentiService from "@/services/UtentiService";
import ScontiService, {ScontiRequest, TipiSconti} from "@/services/ScontiService";
import SportService from "@/services/SportService";
import PromozioniService, {PromozioniRequest} from "@/services/PromozioniService";
import {Filtro} from "@/Classes/FiltroClass";
import StruttureService, {StruttureResponse} from "@/services/StruttureService";
import router from "@/router";

class FormPromo {
  sport: any
  giorniSelezionati: number[]
  date: Date[]
  startTime: string
  endTime: string
  pagamentoOnline: boolean
  tipoPromo: number
  sconto: number
  descrizione: string

  public constructor() {
    this.sport = null
    this.giorniSelezionati = []
    this.date = []
    this.startTime = ''
    this.endTime = ''
    this.pagamentoOnline = false
    this.tipoPromo = 0
    this.sconto = 0
    this.descrizione = ''
  }
}

class FormDiscount {
  utente: number | null
  sport: any
  tipo: any
  tipoSconto: number
  sconto: number
  codiceSconto: string
  utilizzi: number
  date: Date[]

  public constructor() {
    this.utente = null
    this.sport = null
    this.tipo = null
    this.tipoSconto = 0
    this.sconto = 0
    this.codiceSconto = ''
    this.utilizzi = 1
    this.date = []
  }
}

class FormCoupon {
  codice_coupon: string
  numero_codici: number
  valore_coupon: number
  tipo_coupon: number
  date: Date[]
  sport: any


  public constructor() {
    this.codice_coupon = ""
    this.numero_codici = 0
    this.valore_coupon = 1
    this.tipo_coupon = 0
    this.date = []
    this.sport = null
  }
}

@Component({
  components: {
    Toolbar,
  },
})
export default class AddDiscount extends Vue {
  filter: string | (string | null)[] = this.$route.query.filter
  giorniOptions = [
    {"id": 0, "label": "Lunedì"},
    {"id": 1, "label": "Martedì"},
    {"id": 2, "label": "Mercoledì"},
    {"id": 3, "label": "Giovedì"},
    {"id": 4, "label": "Venerdì"},
    {"id": 5, "label": "Sabato"},
    {"id": 6, "label": "Domenica"}
  ];

  giorniSettimana = [false, false, false, false, false, false, false]

  rules = {
    id_struttura: [{required: true, message: 'Seleziona una struttura'}],
    giorniSelezionati: [{required: true, message: 'Selezionare almeno un girono', trigger: 'blur'}],
    date: [{required: true, message: 'Selezionare le date', trigger: 'blur'}],
    startTime: [{required: true, message: 'Scegliere un orario di inizio', trigger: 'blur'}],
    endTime: [{required: true, message: 'Scegliere un orario di fine', trigger: 'blur'}],
    tipo: [{required: true, message: 'Scegliere una modalità', trigger: 'change'}],
    tipoPromo: [{required: true, message: 'Selezionare un tipo', trigger: 'change'}],
    tipoSconto: [{required: true, message: 'Selezionare un tipo', trigger: 'change'}],
    tipo_coupon: [{required: true, message: 'Selezionare un tipo', trigger: 'change'}],
    utente: [{required: true, message: 'Inserire un utente', trigger: 'blur'}],
    codiceSconto: [{required: true, message: 'Inserire un nome', trigger: 'blur'}],
    codice_coupon:[{required: true, message: 'Inserire un nome', trigger: 'change'}],
    numero_codici: [{required: true, message: 'Inserire il numero di utilizzi', trigger: 'blur'}]
  }

  public formPromo: FormPromo = new FormPromo();
  public formDiscount: FormDiscount = new FormDiscount();
  public formCoupon: FormCoupon = new FormCoupon();
  public datiStruttura: StruttureResponse = []
  public tipiSconti: TipiSconti = []
  public filtro = new Filtro();
  public listaSport: any = []
  public listaUtenti: any = [];
  public loading: boolean = false;

  public datePickerOptions: any = {
    disabledDate(date: any) {
      return date < new Date().setDate(new Date().getDate() - 1)
    }
  }

  async add() {
    const loginForm = this.$refs.form as any;

    loginForm.validate(async (valid: boolean) => {
      if (this.filter === "discounts") {
        if (valid) {
          if(this.formDiscount.tipo === null){
            this.formDiscount.tipo = 1
          }
          const sconto: ScontiRequest = {
            codice_sconto: this.formDiscount.codiceSconto,
            valore_sconto: this.formDiscount.tipoSconto == 2 ? this.formDiscount.sconto*100 : this.formDiscount.sconto,
            utente: {
              id: this.formDiscount.utente
            },
            tipo_sconto_buono: {
              id: this.formDiscount.tipo
            },
            tipo_sconto: {
              id: this.formDiscount.tipoSconto
            },
            max_eventi: this.formDiscount.tipo !== 3 ? this.formDiscount.utilizzi : 0,
            start_time: this.formDiscount.date[0].toISOString(),
            expire_time: this.formDiscount.date[1].toISOString(),
            sport: this.formDiscount.sport != null ? {
              id: this.formDiscount.sport
            } : null,
            struttura: {
              id: this.filtro.id_struttura
            }
          }

          const response = await ScontiService.PushSconto(sconto)
          if (response.error) {
            this.$message.error(response.msg)
          } else {
            await this.$router.push("discounts")
          }
        }
      } else if (this.filter === "promo") {
        if (valid) {
          for (let i = 0; i < this.formPromo.giorniSelezionati.length; i++) {
            this.giorniSettimana[this.formPromo.giorniSelezionati[i]] = true
          }
          const promo: PromozioniRequest = {
            giorno_settimana: this.giorniSettimana,
            ora_inizio_promozione: this.formPromo.startTime,
            ora_fine_promozione: this.formPromo.endTime,
            start_time: this.formPromo.date[0].toISOString(),
            expire_time: this.formPromo.date[1].toISOString(),
            des_promozione: this.formPromo.descrizione,
            valore_promozione: this.formPromo.tipoPromo == 2 ? this.formPromo.sconto*100 : this.formPromo.sconto,
            pagamento_online: this.formPromo.pagamentoOnline,
            sport: this.formPromo.sport != null ? {
              id: this.formPromo.sport
            } : null,
            tipo_promozione: {
              id: this.formPromo.tipoPromo
            },
            id_struttura: this.filtro.id_struttura
          }

          const response = await PromozioniService.PushPromozione(promo)
          if (response.error) {
            this.$message.error(response.msg)
          } else {
            await this.$router.push("discounts")
          }
        }
      } else {
        if (valid){
          const codSconto : ScontiRequest = {
            codice_sconto: this.formCoupon.codice_coupon,
            valore_sconto: this.formCoupon.tipo_coupon == 2 ? this.formCoupon.valore_coupon*100 : this.formCoupon.valore_coupon,
            utente: {
              id: null
            },
            tipo_sconto_buono: {
              id: 2
            },
            tipo_sconto: {
              id: this.formCoupon.tipo_coupon
            },
            max_eventi: 0,
            start_time: this.formCoupon.date[0].toISOString(),
            expire_time: this.formCoupon.date[1].toISOString(),
            sport: this.formCoupon.sport != null ? {
              id: this.formCoupon.sport
            } : null,
            struttura: {
              id: this.filtro.id_struttura
            }
          }
          const response = await ScontiService.PushCodiciSconto(codSconto, this.formCoupon.numero_codici)
          if (response.error) {
            this.$message.error(response.msg)
          } else {
            await this.$router.push("discounts")
          }
        }
      }
    })
  }

  public async RiceviStrutture() {
    try {
      const response = await StruttureService.getStrutture();

      if (response.error) {
        this.$message.error(response.msg)
      } else {
        this.datiStruttura = response.data;
        this.filtro.id_struttura = this.datiStruttura[0].struttura.value
      }
    } catch (err: any) {

    }
  }

  public async RiceviTipiSconti() {
    try {
      const response = await ScontiService.ListaTipiSconto();

      if (response.error) {
        this.$message.error(response.msg)
      } else {
        this.tipiSconti = response.data
      }

      for (let i = 0; i < this.tipiSconti.length; i++) {
        if (this.tipiSconti[i].id == 2) {
          this.tipiSconti.splice(i, 1)
        }
      }
    } catch (err: any) {

    }
  }

  public async RiceviSport(){
    const response = await SportService.ListaSportStruttura(this.filtro.id_struttura)
    this.listaSport = response.data.map(x => {
      return {
        nome: x.nome,
        id: x.id,
      }
    })
  }

  async ricercaUtenti(filtro: string) {
    try {
      this.loading = true;
      this.listaUtenti = []
      if (filtro.length > 2) {
        const responseUtenti = await UtentiService.getUtenti(filtro.toUpperCase());
        this.loading = false;
        this.listaUtenti = responseUtenti.data.map(x => {
          return {
            label: `${x.cognome} ${x.nome} -  ${x.cellulare}`,
            value: x.id
          }
        })
      }
    } catch (error: any) {
      this.loading = false;
      this.$message.error(error.message);

    }
  }

  get showPromo(): number {
    if (this.filter === "promo")
      return 1
    else if (this.filter === "discounts")
      return 2
    else
      return 3
  }

  resetImporto() {
    this.formPromo.sconto = 1;
    this.formDiscount.sconto = 1;
  }

  async mounted() {
    await this.RiceviStrutture();
    await this.RiceviTipiSconti();
    await this.RiceviSport()
  }
}
