import BaseService, { APIResponse } from "./BaseService";
import {DateTime} from "luxon";

export type StruttureResponse = {
    struttura:{
        label:string,
        value:number,
      info:Struttura
    }
}[];

export  interface Struttura{
  id: number,
  nome: string,
  descrizione: string,
  sito_web: string,
  telefono: string,
  email:string,
  orario_apertura: string [],
  orario_chiusura: string [],
  annullabile: boolean
  rimborsabile: boolean
  ore_per_annullamento: number,
  commento_obbligatorio: boolean,
  media: string [],
  comune:string,
  istat_comune: string,
  provincia: string,
  istat_provincia:string,
  indirizzo: string,
  logo: string,
  aperto: boolean,
  posizione:{
    type:string,
    coordinates:number[]
  }
  servizi_di_cortesia:[]
  id_subscription: string,
  subscription_price: number,
  subscription_period_end:string
}

export type StrutturaRequest ={
  nome: string
  descrizione: string
  sito_web: string
  telefono: string
  email: string
  orario_chiusura: string[]
  orario_apertura: string[]
  annullabile: boolean
  rimborsabile: boolean
  ore_per_annullamento: number,
  commento_obbligatorio:boolean
}

export default class AuthService extends BaseService {

    static async getStrutture(): Promise<APIResponse<StruttureResponse>> {
      return await this.perform<StruttureResponse>({
        url: "operatore/strutture",
        method: "GET",
      });
    }

    static async updateStruttura(datiStruttura: StrutturaRequest, idStruttura: number): Promise<APIResponse<StruttureResponse>> {
      return await this.perform<StruttureResponse>({
        url: "operatore/strutture/"+ idStruttura,
        method: "POST",
        body: datiStruttura,

      })
    }

    static async bloccaStruttura(id_struttura: number): Promise<APIResponse<StruttureResponse>> {
      return await this.perform<StruttureResponse>({
        url:"operatore/strutture/disabilita/" + id_struttura,
        method: "POST"
      })
    }

  static async deleteMediaStruttura(idStruttura: number, index:number): Promise<APIResponse<object>> {
    return await this.perform<StruttureResponse>({
      url: "operatore/strutture/deletemedia/"+ idStruttura + "/" + index,
      method: "PUT"
    })
  }
  static async getStruttura(id:number): Promise<APIResponse<StruttureResponse>> {
    return await this.perform<StruttureResponse>({
      url: "operatore/strutture/"+id,
      method: "GET",
    });
  }
}
